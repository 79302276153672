import React, { Component } from 'react';
import {Line} from 'react-chartjs-2';
import {db} from '../../firebase';
import PageHeader from '../PageComponents/PageHeader';
import WithAuthentication from '../Authentication/WithStarAuthentication';
import ExportToCSV from './ExportToCSV';
import randomColorRGB from 'random-color-rgb';
import loaderImg from '../../Content/images/load.svg';
class StarAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
			competitors: [],
			userStats: [],
			stats: [],
			usersWithProgress: [],
			startDate: new Date(Date.UTC(2020,4,1)),
			endDate: new Date(Date.UTC(2020,9,1)),
			mappedStats: [],
			filteredUsers: [],
			activeUserData: {},
			userActive: {},
			activeStats: [],
			userOpen: false,
			activeUser: {
				name: '',
				email: '',
			},
			exportStats: [],
			loaded: false,
			searchField: '',
			filter: false,
        }
	}
	handleSearchField({target}){
		
		this.setState({
			searchField: target.value,
		})
		if(target.value.length > 2){
			this.handleFilterUsers()
		}
	}
	handleSearchKeyPress(e){
		if(e.key === 'Enter'){
			this.handleFilterUsers()
		}
	}
	handleFilterUsers(){
		let filteredItems = this.state.mappedStats;
		filteredItems = this.state.mappedStats.filter((item) =>{
			let recipes = `${item.name} ${item.email}`
			return recipes.indexOf(this.state.searchField) !== -1	
		})
		this.setState({
			filteredUsers: filteredItems,
			filter: true,
		})
	}
	componentDidMount() {
        db.getAllUsers()
        .then((users)=>{
            let ids = Object.keys(users).map((item, key)=>{
                if(users[item].challenge === 'Star of ALSDII™ 2020'){
                    return users[item]
                }
                else {return undefined}
            })
            let competitors = ids.filter(Boolean) 
            this.setState({competitors: competitors})
			this.getStats(competitors);
			this.setState({loaded: true})
        })
        .catch((err)=>console.log(err))      
	}
	
    getStats(competitors){
		this.setState({competitors})
		let userStats = [];
		let mappedStats = [];
		let exportStats = [];
		let stats = competitors.map((item)=>{
			if(item.Progress_Tracker){
				let data = this.mapProgress(item.Progress_Tracker);
				mappedStats.push(this.mapStats(item.Progress_Tracker, item));
				let progressStats = this.mapStats(item.Progress_Tracker, item)
				let csv = {
					name: progressStats.name,
					email: progressStats.email,
					weight_change: progressStats.weightLoss,
					size_change: progressStats.sizeLoss,
				}
				exportStats.push(csv)
				if(data.length > 1){
					let rgb = randomColorRGB({opacity: 0.5})
					let dataSet = {
						label: item.username,
						fill: false,
						lineTension: 0.1,
						backgroundColor: rgb,
						borderColor: rgb,
						borderCapStyle: 'butt',
						borderDash: [],
						borderDashOffset: 0.0,
						borderJoinStyle: 'miter',
						pointBorderColor: rgb,
						pointBackgroundColor: rgb,
						pointBorderWidth: 1,
						pointHoverRadius: 5,
						pointHoverBackgroundColor: rgb,
						pointHoverBorderColor: rgb,
						pointHoverBorderWidth: 2,
						pointRadius: 5,
						pointHitRadius: 5,
						data: data
					};
				return dataSet
				}
			}
			return undefined
		})
		let usersWithProgress = stats.filter(Boolean);
		this.setState({userStats, usersWithProgress, mappedStats, exportStats})
	}
	minMax() {
		
	}
	mapStats(progress, user){
		let stats = [],
		chart = [],
		weightPoints = [],
		abdomenPoints = [],
		waistPoints = [],
		thighPoints = [],
		weightLoss = 0,
		sizeLoss = 0;
		

		Object.keys(progress).map((yearName, i) => (
			Object.keys(progress[yearName]).map((monthName, i) => (
				Object.keys(progress[yearName][monthName]).map((dayName, i) => {
					if(new Date(Date.UTC(yearName,monthName,dayName)) > this.state.startDate){
						chart.push({
							y: parseInt(progress[yearName][monthName][dayName].weight + 'kg'), 
							x: new Date(Date.UTC(yearName,monthName - 1,dayName)).toLocaleDateString("en-UK")
						})
						stats.push({
							data: progress[yearName][monthName][dayName],
							date: new Date(Date.UTC(yearName,monthName - 1,dayName)).toLocaleDateString("en-UK"),
						})
					}
					if(progress[yearName][monthName][dayName].weight){
						weightPoints.push(parseInt(progress[yearName][monthName][dayName].weight))
					}
					if(progress[yearName][monthName][dayName].abdomen){
						abdomenPoints.push(parseInt(progress[yearName][monthName][dayName].abdomen))
					}
					if(progress[yearName][monthName][dayName].waist){
						waistPoints.push(parseInt(progress[yearName][monthName][dayName].waist))
					}
					if(progress[yearName][monthName][dayName].thigh){
						thighPoints.push(parseInt(progress[yearName][monthName][dayName].thigh))
					}
					return null
				}
				))
			))
		);
		thighPoints = thighPoints.filter(Number)
		waistPoints = waistPoints.filter(Number)
		abdomenPoints = abdomenPoints.filter(Number)
		weightPoints = weightPoints.filter(Number)
		if(weightPoints.length > 1){
			weightLoss = (weightPoints[0]) - (weightPoints[weightPoints.length -1]);

		}
		if(abdomenPoints.length > 1){
			sizeLoss = sizeLoss + (-(abdomenPoints[0]) + (abdomenPoints[abdomenPoints.length -1]))
		}
		if(waistPoints.length > 1){
			sizeLoss = sizeLoss + (-(waistPoints[0]) + (waistPoints[waistPoints.length -1]))
		}
		if(thighPoints.length > 1){
			sizeLoss = sizeLoss + (-(thighPoints[0]) + (thighPoints[thighPoints.length -1]))
		}
		
		return {
			stats: stats,
			name: user.username,
			email: user.email,
			chart: chart,
			thighPoints,
			waistPoints,
			abdomenPoints,
			weightPoints,
			sizeLoss,
			weightLoss: -(weightLoss),
		}
	}
	mapProgress(user){
		let stats = []
		Object.keys(user).map((yearName, i) => (
			Object.keys(user[yearName]).map((monthName, i) => (
				Object.keys(user[yearName][monthName]).map((dayName, i) => {
					if(new Date(Date.UTC(yearName,monthName,dayName)) > this.state.startDate){
					stats.push({
						y: parseInt(user[yearName][monthName][dayName].weight + 'kg'), 
						x: new Date(Date.UTC(yearName,monthName - 1,dayName)).toLocaleDateString("en-UK")
					})
					}
					return null
				}
				))
			))
		);
		return stats;
	}
	changeUserView(user, name, email, stat, weightLoss, sizeLoss){
		window.scrollTo(0,100)
		this.setState({
			activeUserData:{
				label: `${name} (${email})`,
				fill: true,
				lineTension: 0.5,
				backgroundColor: 'rgba(241,91,41,0.4)',
				borderColor: 'rgba(241,91,41,1.0)',
				borderCapStyle: 'butt',
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: 'miter',
				pointBorderColor: 'rgba(241,91,41,1.0)',
				pointBackgroundColor: '#fff',
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: 'rgba(241,91,41,1.0)',
				pointHoverBorderColor: 'rgba(77,89,113,1.0)',
				data: user,
			},
			userActive: true,
			activeStats: stat,
			activeWeightLoss: weightLoss,
			activeSizeLoss: sizeLoss,
			activeUser: {
				name:  name,
				email: email,
			},
			userOpen: true,
		})
	}
	render() {

		let singleActiveUser =  this.state.userActive === true; 
		let filterActive = this.state.searchField.length > 0 && this.state.filter === true;
		let filteredUsers = this.state.filteredUsers.map((item, key)=>
		  <div key={key}>
			<UserStatsMap 
				key={key} 
				stat={item.stats} 
				chart={item.chart} 
				name={item.name} 
				email={item.email} 
				weightLoss={item.weightLoss}
				sizeLoss={item.sizeLoss}
				changeUserView={this.changeUserView.bind(this)}
			/>
		  </div>
		)
		let noUsersFound = this.state.filteredUsers.length === 0;
		let noUsers = this.state.mappedStats.length === 0;
		let mappedStats = this.state.mappedStats.map((item, key)=>
		  <div key={key}>
				<UserStatsMap 
					key={key} 
					stat={item.stats} 
					chart={item.chart} 
					name={item.name} 
					email={item.email} 
					weightLoss={item.weightLoss}
					sizeLoss={item.sizeLoss}
					changeUserView={this.changeUserView.bind(this)}
				/>
		  </div>
		)
		let activeUserData = {
			datasets: [
				this.state.activeUserData
			]
		}
		let loaded = this.state.loaded === true;
		let userOpen = this.state.userOpen === true;
		let options = {
			title: {text: "This is a test"},
			scales: {
				xAxes: [{
					title: "time",
					type: 'time',
					gridLines: {
						lineWidth: 0
					},
					time: {
						unit: "day",
						unitStepSize: 30,
						displayFormats: {
							millisecond: 'MMM DD',
							second: 'MMM DD',
							minute: 'MMM DD',
							hour: 'MMM DD',
							day: 'MMM DD',
							week: 'MMM DD',
							month: 'MMM DD',
							quarter: 'MMM DD',
							year: 'MMM DD',
						}
					}
				}]
			}
		}
		return (
			<main>
				<div className="py-10">
					<PageHeader mainHeading="ALSDII Star Challenge" subHeading="Track contestants progress"/>
				</div>
				<div className="w-full pageGrid">
					<div className="pb-40">
					{userOpen&&
					<div className="rounded-lg my-4 border border-gray-200 p-5">
						{/* <Line options={options} data={data}/> */}
						<div className="flex flex-wrap">
							
							<div className="w-full lg:w-6/12 p-4">
								<div className="mb-4">
									<span className="block text-primary font-bold mb-0 leading-snug">{this.state.activeUser.name}</span>
									<span className="block text-secondary font-medium text-xs">{this.state.activeUser.email}</span>
								</div>
								<ProgressTable stats={this.state.activeStats}/>
								
								<div className="w-full flex">
									<div className="p-4 mx-auto border-gray-200 border w-6/12">
										<strong className="text-secondary">Total Weight Change:</strong> <span className="text-primary font-medium">{(this.state.activeWeightLoss)}kg</span>
									</div>
									<div className="p-4 mx-auto border-gray-200 border w-6/12">
										<strong className="text-secondary">Total Size Change:</strong> <span className="text-primary font-medium">{this.state.activeSizeLoss}cm</span>
									</div>
								</div>
							</div>
							<div className="w-full lg:w-6/12 p-4">
								{singleActiveUser&&<Line options={options} data={activeUserData}/>}
							</div>
							
							
						</div>
					</div>
					}
					
					{loaded&&
					<>	
						<div className="px-2 flex w-full mb-4 flex-end">
							<div className="w-6/12">
								<label className="block font-bold text-sm text-secondary mb-2" htmlFor="search">
									Search for a user
								</label>
								<div className="flex items-center">
									<span className="flaticon-search mr-4 text-2xl text-secondary"></span>
									<input
										type="text"
										className="formControl"
										placeholder="Search for user"
										name="search"
										onKeyPress={this.handleSearchKeyPress.bind(this)}
										onChange={this.handleSearchField.bind(this)}
									/>
								</div>
							</div>
							<ExportToCSV data={this.state.exportStats} filename="Star Chalenge Data"/>
						</div>
						
						<div className="flex w-full items-center my-2 p-2 text-sm">
							<strong className="w-6/12 text-secondary">Name</strong>
							<strong className="w-4/12 text-secondary">Email</strong>
							<strong className="w-2/12 text-secondary text-center">Progress</strong>
						</div>
					</>
					}
					{loaded?
					<>
						{filterActive?
						<div>
							{noUsersFound&&<h2>No Users found</h2>}
							{filteredUsers}
						</div>
						:
						<div>
							{noUsers&&<h2>No Users found</h2>}
							{mappedStats}
						</div>
						}
					</>
					:
					<div className="text-3xl font-bold text-primary text-center py-4">
						Loading Challengers
						<div className="w-32 mx-auto my-4">
							<img src={loaderImg} alt="loading img"/>
						</div>
					</div>
					}
					</div>
				</div>
			</main>
		);
	}
}

export class CompetitorInfo extends Component {
	render() {
		return (
			<ul className="flex w-full text-sm oddRow items-center border-b border-gray-300 p-2">
				<li className="w-4/12 p-2 inline-block">{this.props.user.username}</li>
				<li className="w-4/12 p-2 inline-block">{this.props.user.email}</li>
				<li className="w-1/12 p-2 inline-block text-center">{this.props.user.Journal?
					<span className="flaticon-check-1 text-2xl text-green-400"></span>
					:
					<span className="flaticon-cancel-2 text-2xl text-orange-400"></span>
				}
				</li>
				<li className="w-1/12 p-2 inline-block text-center">{this.props.user.stats?
					<span className="flaticon-check-1 text-2xl text-green-400"></span>
					:
					<span className="flaticon-cancel-2 text-2xl text-orange-400"></span>
				}
				</li>
				<li className="w-1/12 p-2 inline-block text-center">{this.props.user.Shopping_List?
					<span className="flaticon-check-1 text-2xl text-green-400"></span>
					:
					<span className="flaticon-cancel-2 text-2xl text-orange-400"></span>
				}
				</li>
				<li className="w-1/12 p-2 inline-block text-center">{this.props.user.Meal_Plans?
					<span className="flaticon-check-1 text-2xl text-green-400"></span>
					:
					<span className="flaticon-cancel-2 text-2xl text-orange-400"></span>
				}
				</li>
			</ul>
		)
	}
}

export class UserStatsMap extends Component {
	changeUserView(){
		this.props.changeUserView(this.props.chart, this.props.name, this.props.email, this.props.stat, this.props.weightLoss, this.props.sizeLoss)
	}
	render(){
		let hasStats = this.props.chart.length > 1;
		return (
			<>
				{hasStats&&
				<button className="flex w-full items-center p-2 text-sm cursor-pointer border-b border-gray-200" onClick={this.changeUserView.bind(this)}>
					<h5 className="w-6/12 font-medium text-secondary">{this.props.name}</h5>
					<h4 className="w-4/12">{this.props.email}</h4>
					<h4 className="w-2/12 text-center">{this.props.chart.length}</h4>
				</button>
				}
			</>
		)
	}
}
export class ProgressPoint extends Component {
	
	render(){
		return (
			<>
			{this.props.data&&
			<tr className="oddRow border-b">
				<td className="p-2 text-xs">{this.props.date}</td>
				{this.props.data.weight? <td className="p-2 text-xs text-center">{this.props.data.weight}kg</td>:<td className="p-2 text-xs text-center">-</td>}
				{this.props.data.waist?<td className="p-2 text-xs text-center">{this.props.data.waist}cm</td>:<td className="p-2 text-xs text-center">-</td>}
				{this.props.data.abdomen?<td className="p-2 text-xs text-center">{this.props.data.abdomen}cm</td>:<td className="p-2 text-xs text-center">-</td>}
				{this.props.data.thigh?<td className="p-2 text-xs text-center">{this.props.data.thigh}cm</td>:<td className="p-2 text-xs text-center">-</td>}
				{this.props.data.bmr?
					<td className="p-2 text-xs text-center">{this.props.data.bmr.toFixed(0)}</td>:<td className="p-2 text-xs text-center">-</td>
				}
			</tr>
			}
			</>
		)
	}
}

export class ProgressTable extends Component {
	
	render() {
		let stats = this.props.stats.map((item, key)=>
			<ProgressPoint data={item.data} date={item.date} key={key}/>
		)
		return (
			<table className="w-full my-4 text-sm">
					<tbody>
						<tr>
							<th className="p-2 text-left text-sm font-bold">Date</th>
							<th className="p-2 text-left text-sm font-bold text-center">Weight</th>
							<th className="p-2 text-left text-sm font-bold text-center">Waist</th>
							<th className="p-2 text-left text-sm font-bold text-center">Abdomen</th>
							<th className="p-2 text-left text-sm font-bold text-center">Thigh</th>
							<th className="p-2 text-left text-sm font-bold text-center">BMR</th>
						</tr>
						{stats}
					</tbody>
				</table>
		)
	}
}

export default WithAuthentication(StarAdmin)
