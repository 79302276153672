import React, { Component } from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
export default class ExportToCSV extends Component {
    exportFile(){
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(this.props.data);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const xsdata = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(xsdata, this.props.filename + fileExtension);
    }
    render() {
        return (
            <div className="w-6/12 text-right">
                 <button variant="warning" className="inline-block ml-auto bg-primary text-white rounded-lg my-4 py-2 px-6" onClick={this.exportFile.bind(this)}>Export To Excel File</button>
            </div>
        )
    }
}
