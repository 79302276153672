import React, {Component} from "react";
import {
  storeUser,
  storeUserId,
  setStarAdmin,
} from "../redux/actions/";
import StarAdmin from '../components/Challenges/StarAdmin';
import { Router } from "@reach/router";
import { navigate } from "gatsby";
import {db} from '../firebase';
import {connect} from "react-redux";
import firebase from 'gatsby-plugin-firebase';
function mapDispatchToProps(dispatch) {
    return {
      storeUser: user => dispatch(storeUser(user)),
      storeUserId: id => dispatch(storeUserId(id)),
      setStarAdmin: admin => dispatch(setStarAdmin(admin)),
    };
  }
  class ChallengeAdmin extends Component {
    constructor(props) {
      super(props);
      this.state = {
        authUser: '',
      }
    }
    componentDidMount() {
      firebase.auth().onAuthStateChanged((authUser)=>{
        if(!authUser){
          navigate('/login')
        }
        else {
          db.getUserInfo(authUser.uid)
          .then((user)=>{
            this.props.storeUser(user)
            if(user.star_admin){
                this.props.setStarAdmin(user.star_admin);
                this.setState({authUser})
            }
          })
        }
      })
    }
    render() {
      const {authUser} = this.state;
      return (
        <React.Fragment>
        {authUser&&
        <Router basepath="/challenge-admin">
            <StarAdmin path="/star"/>
        </Router>
        }
        </React.Fragment>
      )
    }
  }
  
  const ConnectedChallengeAdmin = connect(null, mapDispatchToProps)(ChallengeAdmin);
  export default ConnectedChallengeAdmin;
  
  