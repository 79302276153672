import React, { useEffect } from "react";
import { connect } from "react-redux";
import {navigate} from 'gatsby';
export default ChildComponent => {
  const ComposedComponent = props => {
    useEffect(() => {
      if (props.starAdmin === false) return navigate("/");
    }, [props.auth, props.starAdmin]);
    return <ChildComponent {...props} />;
  };
  function mapStateToProps(state) {
    return {
      starAdmin: state.starAdmin
    };
  }
  return connect(mapStateToProps)(ComposedComponent);
};